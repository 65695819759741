// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-2021-covid-19-philadelphia-public-schools-digital-divide-js": () => import("./../../../src/pages/2021/covid-19-philadelphia-public-schools-digital-divide.js" /* webpackChunkName: "component---src-pages-2021-covid-19-philadelphia-public-schools-digital-divide-js" */),
  "component---src-pages-2021-dining-guide-fall-js": () => import("./../../../src/pages/2021/dining-guide-fall.js" /* webpackChunkName: "component---src-pages-2021-dining-guide-fall-js" */),
  "component---src-pages-2021-dining-guide-spring-js": () => import("./../../../src/pages/2021/dining-guide-spring.js" /* webpackChunkName: "component---src-pages-2021-dining-guide-spring-js" */),
  "component---src-pages-2021-love-issue-js": () => import("./../../../src/pages/2021/love-issue.js" /* webpackChunkName: "component---src-pages-2021-love-issue-js" */),
  "component---src-pages-2021-penn-10-js": () => import("./../../../src/pages/2021/penn-10.js" /* webpackChunkName: "component---src-pages-2021-penn-10-js" */),
  "component---src-pages-2021-penn-interrupted-js": () => import("./../../../src/pages/2021/penn-interrupted.js" /* webpackChunkName: "component---src-pages-2021-penn-interrupted-js" */),
  "component---src-pages-2021-pennsylvania-2020-election-philadelphia-democratic-republican-js": () => import("./../../../src/pages/2021/pennsylvania-2020-election-philadelphia-democratic-republican.js" /* webpackChunkName: "component---src-pages-2021-pennsylvania-2020-election-philadelphia-democratic-republican-js" */),
  "component---src-pages-2021-pre-med-humanities-covid-19-pandemic-medicine-penn-js": () => import("./../../../src/pages/2021/pre-med-humanities-covid-19-pandemic-medicine-penn.js" /* webpackChunkName: "component---src-pages-2021-pre-med-humanities-covid-19-pandemic-medicine-penn-js" */),
  "component---src-pages-2022-dining-guide-spring-js": () => import("./../../../src/pages/2022/dining-guide-spring.js" /* webpackChunkName: "component---src-pages-2022-dining-guide-spring-js" */),
  "component---src-pages-2022-isolation-js": () => import("./../../../src/pages/2022/isolation.js" /* webpackChunkName: "component---src-pages-2022-isolation-js" */),
  "component---src-pages-2022-love-issue-js": () => import("./../../../src/pages/2022/love-issue.js" /* webpackChunkName: "component---src-pages-2022-love-issue-js" */),
  "component---src-pages-2022-penn-10-js": () => import("./../../../src/pages/2022/penn-10.js" /* webpackChunkName: "component---src-pages-2022-penn-10-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

